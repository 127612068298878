import React from 'react';
import { Editor, EditorState, convertFromRaw } from "draft-js";

const TextEditorText = ({data}) => {
    const contentState = convertFromRaw(data);
    const editorState = EditorState.createWithContent(contentState);

    const renderContent = () => {
        const blocks = data.blocks;
        const iframeBlock = blocks.find(block => block.text.includes('<iframe'));

        if (iframeBlock) {
            const iframeHTML = iframeBlock.text;
            return <div dangerouslySetInnerHTML={{ __html: iframeHTML }} />;
        } else {
            return <Editor editorState={editorState} readOnly={true} />;
        }
    };

    return (
        <div className="data-container" style={{fontFamily: 'Nimbus-Regular', lineHeight: '160%'}}>
            {renderContent()}
        </div>
    );
}

export default TextEditorText;