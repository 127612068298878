import React, { useRef, useContext } from 'react';

/*OWN*/
import './Home.scss';
import scrollDownButton from '../assets/scrollDownArrow.svg';
import pageHeroBg from '../assets/pageHeroBg.jpg';
import GridSection from '../Components/pages/HomePage/sections/GridSection';
import InteractiveImgSection from '../Components/pages/HomePage/sections/InteractiveImgSection';
import FormSection from '../Components/pages/HomePage/sections/FormSection';
import BackendDataContext from '../Context/BackendDataContext';



const Home = () => {
    
    const scrollToRef = (ref) => window.scrollTo({left: 0, top: ref.current.offsetTop, behavior: 'smooth'}) 
    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    const { currentState } = useContext(BackendDataContext);
    const pages = [...currentState.pages];
    const page = pages.find((page)=>page.id === 1);
    
    return(
        <>
        {page &&
        <div className="page-container">
            <div className="hero-video-bg-container">
                <div id="relative-container">
                    <div className="hero-bg-cover"></div>
                    <img src={pageHeroBg} alt="Page hero" style={{height: '100%', width: '100%', objectFit: 'cover'}}/>
                    <video loop autoPlay="autoplay" id="vid" muted playsInline>
                        <source src="/video/video_uvod.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <div className="hero-content">
                <div className="title-scroll-container">
                    <img src={scrollDownButton} alt="Tlačítko posunout dolů" onClick={executeScroll}/>
                </div>
            </div>
            <GridSection refProp={myRef} page={page}/>
            <InteractiveImgSection />
            <FormSection />
        </div>}
        </>
    );
}

export default Home;