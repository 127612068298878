import React, { useContext } from 'react';
import HouseChoice from '../../../InteractiveHouseChoice/HouseChoice';
import BackendDataContext from '../../../../Context/BackendDataContext';
import { useWindowSize } from '../../../../Utils/useWindowSize';
import MobileHouseChoice from '../../../MobileHouseChoice/MobileHouseChoice';

/*SASS*/
import './InteractiveImgSection.scss';



const InteractiveImgSection = () => {

    const { currentState } = useContext(BackendDataContext);
    const windowSize = useWindowSize();

    return (
        <section className="interactive-img-section">
                <div className="house-choice-heading-and-border-wrapper">
                    <div></div>
                    <div className="house-choice-heading-container">
                        <h1 className="house-choice-heading">Výběr domu</h1>
                    </div>
                    <div className="house-choice-heading-border-helper"></div>
                    {/*<div className="side-border-helper"></div>*/}
                </div>
            <section className="mobile-house-choice-section" style={{marginTop: "100px"}}>
                <MobileHouseChoice houses={currentState.houses} />
            </section>
            {/*<div className="house-choice-map-wrapper">
                <HouseChoice houses={currentState.houses}/>

                <section className="mobile-house-choice-section">
                    <MobileHouseChoice houses={currentState.houses}/>
                </section>
            </div>*/}


            <div className="house-choice-bottom-border-helper"></div>
        </section>
    );
}

export default InteractiveImgSection;