import React, { useContext } from 'react';
import BackendDataContext from '../../../Context/BackendDataContext';
import ImageGallery from '../../ImageGallery/ImageGallery';
import mhLarge from '../../../assets/img/AboutProject/moderni_hajany_velky.jpg';
import mhSmallTop from '../../../assets/img/AboutProject/moderni_hajany_maly_1.jpg';
import mhSmallBottom from '../../../assets/img/AboutProject/moderni_hajany_maly_2.jpg';

/*SASS*/
import './AboutProjectPage.scss';
import TextEditorText from '../../TextEditorText/TextEditorText';

const AboutProjectPage = ({page}) => {

    const { currentState } = useContext(BackendDataContext);

    const pages = [...currentState.pages];
    const images = pages.find(page=>page.id === 2).sections[0].images;

    return (
        <>
        <div className="about-page-container">
            <section className="about-project-section">
                <div className="about-text-editor-container">
                <TextEditorText data={JSON.parse(page.sections[0].content)}></TextEditorText>
                </div>
            </section>
            <ImageGallery images={images}/>
        </div>
        <section className="about-hajany-section">
            <h1>
                Minulý projekt:
            </h1>
            <p>
                Pokud byste si rádi prohlédli domy v Hajanech a získali přesnější představu o naší práci, klikněte níže.
            </p>
            <a href="https://www.realmajor.cz" target="_blank" style={{display: 'block', marginBottom: '10px'}}><button className="outlined-button">Více o projektu Moderní Hajany</button></a>
            <a href="https://moderni-zelesice.cz" target="_blank"><button className="outlined-button">Více o projektu Moderní Želešice</button></a>
            <div className="hajany-imgs-container">
                <img src={mhLarge} id="large-img" alt="Obrázek domu Moderní Hajany velký" loading="lazy"></img>
                <img src={mhSmallTop} id="small-img-top" alt="Obrázek domu Moderní Hajany malý" loading="lazy"></img>
                <img src={mhSmallBottom} id="small-img-bottom" alt="Obrázek domu Moderní Hajany jiný pohled" loading="lazy"></img>
            </div>
        </section>
        </>
    );
}

export default AboutProjectPage;